<template>
	<b-form-group :label-for="id" a>
		<template slot="label">
			{{label}} <span v-if="required" class="label-star">*</span>
		</template>
		<b-form-input
				:type="type"
				:id='id'
				class="text_input"
				:debounce='debounce'
				:placeholder="placeholder"
				v-model="changedValue"
				:required="required"
				:state='state'
				:aria-describedby="`input-live-feedback-${id}`"
		></b-form-input>
		<b-form-invalid-feedback v-if="error" :id="`input-live-feedback-${id}`">
			{{error}}
		</b-form-invalid-feedback>
	</b-form-group>

</template>

<script>
    export default {
        name: 'app-input',
        data() {
            return {
                changedValue: ''
            }
        },
		props : {
            id: {
                type: String,
                required: false,
                default: Date.now()
            },
            placeholder: {
                type: String,
                required: false,
                default: ''
            },
            type: {
                type: String,
                required: false,
                default: 'text'
            },
            error: {
                type: String,
                required: false,
                default: null
            },
            value: {
                type: [String, Number]
            },

            label: {
                type: String,
                required: false,
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },

            debounce: {
                type: Number,
                required: false,
                default: 0
            }
        },
        created() {
            this.changedValue = this.value;
        },
        computed: {
            state() {
               return this.error == null ? null : false;
            }
        },
        watch: {
            changedValue: function () {
                this.$emit('input', this.changedValue);
            }
        }
    }
</script>

<template>
	<div>
		<b-card no-body>
			<b-card-header role="collapse" >
				<button class="btn btn-link btn-block text-left" type="button"
						v-b-toggle="`accordion-${ident}`">
					<span class="btn-txt"><i :class="`icon-${icon}`"></i>{{title}}</span>
					<span class="btn-icon"><i class="icon-arrow-down"></i></span>
				</button>
			</b-card-header>
			<b-collapse :id="`accordion-${ident}`" visible role="tabpanel">
				<b-card-body>
					<slot></slot>
				</b-card-body>
			</b-collapse>
		</b-card>


	</div>


</template>

<script>
    export default {
        name: "Accordion",
        props: {
            title: {
                type: String,
                required: false,
                default: 'Accordion'
            },
            ident: {
                type: String,
                required: true
            },
			icon: {
                type: String,
                required: false,
                default: 'theme'
			}

        }
    }
</script>

<template>
	<div class="form-group">
		<label>{{label}}</label>
		<p v-if="description">{{description}}</p>
		<div :class="{'form-check-list': !inline, 'form-group-check': inline }">
			<div class="form-check custom-control custom-radio" v-for="(option, index) in options" :key="option.text">
				<input
						:id="id + index"
						:name="id"
						type="radio"
						:value="option.value"
						:checked="option.value === value"
						class="form-check-input custom-control-input"
						:disabled="disabled"
						:required="required"
						@change="updateValue(option.value)"
				/>
				<label class="form-check-label custom-control-label"
					   :for="id + index"><span>{{ option.text }}</span></label>
			</div>

		</div>
	</div>
</template>

<script>
    export default {
        model: {
            event: "change",
        },
        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            value: {
                type: [String, Number, Boolean, Object],
                default: null,
            },
            options: {
                type: [Array],
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            inline: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                default: null,
            }
        },
        methods: {
            updateValue(value) {
                this.$emit("change", value);
            },
        },
    };
</script>

<template>
	<b-form-group
			:label="label"
			:label-for="id"
	>
		<b-form-select :id="id" v-model="selected" class="form-control" :options="options"></b-form-select>
	</b-form-group>

</template>

<script>
    export default {
        props: {
            options: {
                type: Array
            },
            label: {
                type: String,
                required: false,
                default: ''

            },
            selectedOption: {
                type: String,
            },

            id: {
                type: String,
                required: false,
                default: Date.now().toString()
            }
        },
        data() {
            return {
                selected: null,

            }
        },
        mounted() {
            this.selected = this.selectedOption;
        },
        watch: {
            selected: function () {
                this.$emit('input', this.selected);
            }
        }
    }
</script>

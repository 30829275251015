<template>

	<div class="file js-file" :class="{ 'file--fav' : type ==='favicon'}">

		<div v-if="isLoading">
			<p>
				Uploading your image, please wait...
			</p>
		</div>
		<div v-else>
			<div id="input-live-message" class="invalid-feedback">{{error}}</div>
			<label :for="`file-upload-${type}`" class="file-form js-file-form" :class="{'plain-background': file}"
				   :id="`file-upload-${type}-label`">
				<img v-if="file" :src="file" width="100%" style="object-fit: cover;" :name="type + 'path'"/>
			</label>
			<input :id="`file-upload-${type}`" type="file" :accept="acceptFileTypes" ref="file"
				   v-on:change="uploadFile($event.target.files)"/>
			<div class="file-info">
				<p>Max Dimensions: {{maxWidth}}x{{maxHeight}}</p>
				<p>Max Size: {{reformatSize}}</p>
				<p>Format: {{fileTypesString}}</p>
			</div>
			<div class="file-btns">
				<button class="btn btn-primary js-file-load " @click="chooseFile()">Upload new picture</button>
				<button class="btn btn-secondary js-file-remove" @click="deleteFile()">Delete</button>
			</div>
		</div>
	</div>
</template>
<script>
    import {mapGetters} from "vuex";
    import helper from '../services/helper';


    export default {
        props: {
            path: {
                type: String
            },
            fileTypes: {
                type: Array,
                required: false,
                default: () => {
                    return [
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                    ]
                }
            },
            maxSize: {
                type: Number,
                required: false,
                default: 204800
            },
            type: {
                type: String,
                required: true,
            },
            maxWidth: {
                type: Number,
                required: false,
                default: 600
            },
            maxHeight: {
                type: Number,
                required: false,
                default: 300
            }
        },
        data() {
            return {
                file: '',
                error: '',
                isLoading: false,
                newFileName: '',
                valid: true
            }
        },
        methods: {
            uploadFile(files) {
                let file = files[0];
                let size = file.size;
                this.valid = true;
                this.error = '';

                if (size && size > this.maxSize) {
                    this.error = 'Invalid file size \n';
                    this.valid = false;
                }
                if (!this.validFileType(file)) {
                    this.error += 'Invalid file type\n';
                    this.valid = false;
                }
                if (this.valid) {
                    let reader = new FileReader();

                    reader.readAsDataURL(file);

                    reader.onload = evt => {
                        let img = new Image();
                        img.onload = () => {
                            if (img.width > this.maxWidth) {
                                this.error = `The image width (${img.width}) is too much (max is ${this.maxWidth}).`;
                                return;
                            }
                            if (img.height > this.maxHeight) {
                                this.error += ` \n The image height (${img.height}) is too much (max is ${this.maxHeight}).`;
                                return;
                            }

                            this.isLoading = true;
                            this.newFileName = this.type + "_" + helper.hash(this.user.domain);
                            this.$store.dispatch('setupForm/uploadFile', {file, name:this.newFileName})
                            .then((data) => {
                                this.file = `${data.url}?`+Date.now();
                            }).finally(() => {this.isLoading = false;})
                        };
                        img.src = evt.target.result;
                    };
                    reader.onerror = evt => {
                        this.file = this.path;
                        console.error(evt);
                    };
                }
            },

            chooseFile() {
                document.getElementById("file-upload-" + this.type + "-label").click()

            },

            deleteFile() {
                this.file = null;
            },
            validFileType(file) {
                if(this.fileTypes.includes('image/ico')) {
                   return  file.type === 'image/vnd.microsoft.icon' || file.type === 'image/x-icon' || this.fileTypes.includes(file.type);
                }
                return this.fileTypes.includes(file.type);
            }
        },
        computed: {
            reformatSize() {
                let size = this.maxSize;
                let i = Math.floor(Math.log(size) / Math.log(1024));
                return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
            },
            fileTypesString() {
                return this.fileTypes.map(i => i.replace('image/', '')).join(', ').replace('jpeg,', '');
            },
            acceptFileTypes() {
                let string =  this.fileTypes.join(',' );
                if(this.fileTypes.includes('image/ico')) {
                    string += ', '+ 'image/vnd.microsoft.icon, image/x-icon'
                }
                return string;
			},
            ...mapGetters({
                user: "auth/getUser"
            })
        },
        mounted() {
            this.file = this.path;
        },
        watch: {
            file: function () {
                this.$emit('input', this.file);

            }
        }
    }
</script>
<style scoped>
	.invalid-feedback {
		display: block;
	}
	.plain-background {
		background-image: none;
	}

	input[type="file"] {
		display: none;
	}
</style>
